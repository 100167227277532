export default {
    seo: {
      title: 'Financial statements for 2021 | Bank 131',
      description: 'Financial statements for 2021'
    },
    title: 'Financial statements for 2021',
    content: [
         {
             label: 'Posting date 11.11.2021',
             title: 'Published reports for 9 months of 2021.zip',
             link: '/legal/en/Published_reports_for_9_months_of_2021.zip',
         },
         {
             label: 'Posting date 11.08.2021',
             title: 'Published reports for the first half of 2021.zip',
             link: '/legal/en/Published_reports_for_the_first_half_of_2021.zip',
         },
         {
             label: 'Posting date 14.05.2021',
             title: 'Published reports for the first quarter of 2021.zip',
             link: '/legal/en/Published_reports_for_the_first_quarter_of_2021.zip',
             clarification: {
                 label: 'Posting date 21.04.2021',
                 text: 'The annual shareholder meeting (Bank 131 Joint Stock Company`s sole shareholder decision) was held on April 16, 2021.'
             }
         }
    ]
}