export default {
    seo: {
      title: 'Финансовая отчетность за 2021 год | Банк 131',
      description: 'Финансовая отчетность за 2021 год'
    },
    title: 'Финансовая отчетность за 2021 год',
    content: [
          {
              label: 'Дата размещения 11.11.2021',
              title: 'Промежуточная бухгалтерская (финансовая) отчетность за 9 месяцев 2021 года.pdf',
              link: '/legal/ru/promezhutochnaya_buhgalterskaya_finansovaya_otchetnost_za_9_mes_2021.pdf',
          },
          {
              title: 'Пояснительная информация к отчетности за 9 месяцев 2021 года.pdf',
              link: '/legal/ru/poyasnitelnaya_informaciya_k_otchetnosti_za_9_mes_2021.pdf',
          },
         {
             label: 'Дата размещения 11.08.2021',
             title: 'Промежуточная бухгалтерская (финансовая) отчетность за первое полугодие 2021.pdf',
             link: '/legal/ru/promezhutochnaya_buhgalterskaya_finansovaya_otchetnost_za_pervoe_polugodie_2021.pdf',
         },
         {
             title: 'Пояснительная информация к отчетности за первое полугодие 2021.pdf',
             link: '/legal/ru/poyasnitelnaya_informaciya_k_otchetnosti_za_pervoe_polugodie_2021.pdf',
         },
         {
             label: 'Дата размещения 14.05.2021',
             title: 'Пояснительная информация к отчетности за первый квартал 2021 года.pdf',
             link: '/legal/ru/Poyasnitelnaya_inf_first_kvartal_2021.pdf',
         },
         {
             title: 'Промежуточная бухгалтерская финансовая отчетность за первый квартал 2021 года.pdf',
             link: '/legal/ru/ru_first_kvartal_2021.pdf',
             clarification: {
                 label: 'Дата размещения 21.04.2021',
                 text: 'Дата проведения очередного (годового) общего собрания участников (дата принятия решения ' +
                     'единственного участника Акционерного общества «Банк 131») - 16.04.2021.'
             }
         }
     ]
}